import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from "prop-types"
import clsx from "clsx"

import styles from './index.module.scss'

const MenuItem = ({link, title}) => {
	const { pathname } = useLocation()
	// const match = link.split('/')[1] === pathname.split('/')[1];

	const isServices = (link === '/services/') && (pathname === '/services/logistics/')

	return (
		<NavLink to={link} className={styles.root}>
			{({ isActive }) => (
				<>
				{isActive}
				<span className={ clsx(styles.headerMenuLink, {[styles.active]: (pathname === link) || isServices} )}>{title}</span>
				</>
			)}
		</NavLink>
	)
}

MenuItem.propTypes = {
	link: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
}


export default React.memo(MenuItem)