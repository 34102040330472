import React from 'react'
import PropTypes from 'prop-types'

import MenuItem from '../menuItem'

import styles from './index.module.scss'

const Menu = ({ items }) => {
	return (
		<div className={styles.root}>
				<div className={styles.linkWrapper}>
					<MenuItem
						link={'/'}
						title={'Главная'} />
				</div>
				{
					items.map((e, i) =>
						<div key={i} className={styles.linkWrapper}>
							<MenuItem
								link={e.url}
								title={e.text} />
						</div>
					)
				}
				<div className={styles.linkWrapper}>
					<MenuItem
						link={'/about/epr/'}
						title={'ЭПР'} />
				</div>
		</div>
	)
}

Menu.propTypes = {
	items: PropTypes.array.isRequired
}

export default React.memo(Menu)