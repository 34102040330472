export const fetchDo = async (data, url) => {
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'X-SYNC-ACCESS-TOKEN': 'c0ed1b77-3400-435a-9c50-717ab63f50e8',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	})
		.then(response => response.json())
		.catch(err => console.error(err))

	return response
}