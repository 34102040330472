import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

function FormResult({ title, text }) {
	return (
		<div className={styles.root}>
			<div className={styles.title}>{title}</div>
			<div className={styles.title}>{text}</div>
		</div>
	)
}

FormResult.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
}

export default FormResult