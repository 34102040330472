import React, { useEffect, useRef, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import clsx from 'clsx'

import Menu from './menu'
import MobileMenu from './mobileMenu'
import Button from '../../../../components/common/button'
import { Form } from '../../../contactsForm'
import { ReactComponent as LogoIcon } from '../../../../icons/logo.svg'

import { ModalContext } from '..'
import data from '../../store'

import styles from './index.module.scss'

const Header = ({ type }) => {
	const headerRef = useRef(null)
	const { setModalComponent, setModalState } = useContext(ModalContext)

	const clickHandler = () => {
		setModalState(true)
		setModalComponent(<Form />)
	}

	// useEffect(() => {
	// 	const scrollHandler = throttle(e => {
	// 		if(headerRef.current) {
	// 			if(window.pageYOffset > 5) {
	// 				headerRef?.current.classList.add(styles.scroll)
	// 			} else {
	// 				headerRef?.current.classList.remove(styles.scroll)
	// 			}
	// 		}
	// 	}, 100)

	// 	window.addEventListener('scroll', scrollHandler)

	// 	return () => {
	// 		window.removeEventListener('scroll', scrollHandler)
	// 	}
	// }, [])

	return (
		<div className={clsx(styles.root, {[styles.default]: type === 'default' })} ref={headerRef}>
			<NavLink name='empairo' to={'/'} className={styles.logoWrapper}>
				<LogoIcon />
			</NavLink>
			<div className={styles.menuDesktopWrapper}>
				<div className={styles.menuWrapper}>
					<Menu items={data.links} />
				</div>
			</div>
			<div className={styles.buttonWrapper}>
				<Button text={'Связаться'} isHeader={true} handler={clickHandler}/>
			</div>
			<div className={styles.burgerWrapper}>
				<MobileMenu links={data.links} />
			</div>
		</div>
	)
}

Header.propTypes = {
	type: PropTypes.string,
}

export default Header