import React, { useState } from 'react';

import styles from './index.module.scss'

export const CookiePopup = () => {
  const [showPopup, setShowPopup] = useState(!localStorage.getItem('cookieConsent'));

  const handleAccept = () => {
    setShowPopup(false);
    localStorage.setItem('cookieConsent', 'true');
  };

  if (!showPopup) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <span>Мы используем Яндекс.Метрика и собственные cookies для обеспечения наилучшего опыта работы на нашем сайте, персонализации сайта для наших пользователей и аналитики. Продолжая использовать сайт, вы соглашаетесь с условиями <a href='/assets/license_agreement.pdf' target='_blank'>Пользовательского соглашения</a></span>
      <button onClick={handleAccept}>OK</button>
    </div>
  );
};