import React, { createContext } from 'react'

import Header from './header'
import Footer from './footer/'
import ScrollToTop from './scrollToTop'

import { CookiePopup } from '../../../components/CookiePopup'
import Portal from '../../../components/common/portal'
import Modal from '../../../components/common/modal'
import useModal from '../../../hooks/useModal'

import styles from './index.module.scss'

export const ModalContext = createContext()

const Layout = ({ children, headerType }) => {
	const { modalState, setModalState, modalComponent, setModalComponent } = useModal()

	return (
		<ModalContext.Provider value={{ modalState, setModalState, modalComponent, setModalComponent }}>
			<ScrollToTop />
			<div className={styles.wrapper}>
				<div className={styles.content}>
					<header>
						<Header type={headerType} />
					</header>
					<main className={styles.main}>
						{children}
					</main>
					<footer>
						<Footer />
					</footer>
				</div>
				<CookiePopup/>
				<Portal component={<Modal isVisible={modalState} changeModalState={setModalState} popupContent={modalComponent} />} elemId={'portal'} />
			</div>
		</ModalContext.Provider>
	)
}

export { Layout }