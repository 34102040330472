import React, { Suspense } from "react"
import { createBrowserRouter, Navigate } from "react-router-dom"

import { Layout } from "../../modules/layout/"

const MainPage = React.lazy(() => import("../../pages/main"))
const Epr = React.lazy(() => import("../../pages/epr"))
const About = React.lazy(() => import("../../pages/about"))
const PageNotFound = React.lazy(() => import("../../pages/404"))
const Services = React.lazy(() => import("../../pages/services/"))
const Fleet = React.lazy(() => import("../../pages/fleet"))

const router = createBrowserRouter([
	{
		path: "/",
		element:
			<Suspense fallback={<div>loading...</div>}>
				<Layout children={<MainPage />} headerType={'default'} />
			</Suspense>
	},
	{
		path: "/about/epr/",
		element: 
			<Suspense fallback={<div>loading...</div>}>
				<Layout children={<Epr />} headerType={'custom'} />
			</Suspense>
	},
	{
		path: '/services/logistics/',
		element: 
			<Suspense fallback={<div>loading...</div>}>
				<Layout children={<Services code={'logistics'} />} />
			</Suspense>
	},
	{
		path: '/services/aero/',
		element: 
			<Suspense fallback={<div>loading...</div>}>
				<Layout children={<Services code={'aero'} />} />
			</Suspense>
	},
	{
		path: '/services/agro/',
		element: 
			<Suspense fallback={<div>loading...</div>}>
				<Layout children={<Services code={'agro'} />} />
			</Suspense>
	},
	{
		path: '/fleet/logistics/',
		element: 
			<Suspense fallback={<div>loading...</div>}>
				<Layout children={<Fleet code={'logistics'}/>}/>
			</Suspense>
	},
	{
		path: '/fleet/aero/',
		element: 
			<Suspense fallback={<div>loading...</div>}>
				<Layout children={<Fleet code={'aero'}/>}/>
			</Suspense>
	},
	{
		path: '/fleet/agro/',
		element: 
			<Suspense fallback={<div>loading...</div>}>
				<Layout children={<Fleet code={'agro'}/>}/>
			</Suspense>
	},
	{
		path: '/about/',
		element: 
			<Suspense fallback={<div>loading...</div>}>
				<Layout children={<About />} />
			</Suspense>
	},
	{
		path: '/about/:id',
		element: 
			<Suspense fallback={<div>loading...</div>}>
				<Layout children={<About />} />
			</Suspense>
	},
	{
		path: "/services/",
		element: <Navigate to="/services/logistics/" replace />
	},
	{
		path: "/fleet/",
		element: <Navigate to="/fleet/logistics/" replace />
	},
	{
		path: "/*",
		element: 
			<Suspense fallback={<div>loading...</div>}>
				<Layout children={<PageNotFound />} headerType={'custom'} />
			</Suspense>
	},
])
export default router