import styles from '../index.module.scss';
import clsx from 'clsx'

import { ReactComponent as Checkmark } from '../../../icons/checkmark.svg'

const Checkbox = ({label, message, name, error, register}) => {

  return(
    <label className={styles.checkboxWrap}>
      <input 
        type="checkbox" 
        name={name} 
        {...register(name, { required: message})}
      />
      <div className={styles.holder}>
        <Checkmark />
      </div>
      <span className={styles.label}>
        {label}
      </span>
      <div className={clsx(styles.errorMsg, {
		    	[styles.errorVisible]: error
		    })}>
        {error?.message}
      </div>
    </label>
  )
}

export default Checkbox