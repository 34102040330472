import { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'

const useWindowDimensions = () => {
	const [size, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight
	})

	useEffect(() => {
		const resizeHandler = throttle(e => {
			setWindowSize({
				width: e.target.innerWidth,
				height: e.target.innerWidth
			})
		}, 100)

		window.addEventListener('resize', resizeHandler)

		return () => {
			window.removeEventListener('resize', resizeHandler)
		}
	}, [])

	return size
}

export default useWindowDimensions