import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'

import { ReactComponent as ForwardIcon } from '../../../icons/link-forward-icon.svg'

import styles from './index.module.scss'

const NavigationLink = ({ url, text, handler, isActive }) => {

	const openHandler = () => {
		handler()
	}

	return (
		<NavLink to={url} className={clsx(styles.root, {
			[styles.isActive]: isActive
		})} onClick={handler ? openHandler : null}>
			<span className={styles.link}>{text}</span>
			<div className={styles.iconWrapper}>
				<ForwardIcon />
			</div>
		</NavLink>
	)
}

NavigationLink.propTypes = {
	url: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	isActive: PropTypes.bool,
	handler: PropTypes.func
}

export default React.memo(NavigationLink)
