import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import PhoneInput from './phoneInput'
import SizeInput from './sizeInput'
import TextInput from './textInput'
import TextInputHookFrom from './textInputHookForm'

import styles from './index.module.scss'

const Input = ({ type, message, changeData, Icon, text, error, register, submitErrors }) => {
	const [isFocused, setIsFocused] = useState(false)

	useEffect(() => {
		if(submitErrors && !isFocused) {
			setIsFocused(true)
		}	
	}, [submitErrors, isFocused])

	const focusHandler = () => {
		setIsFocused(true)
	}

	const blurHandler = (e, isError = false) => {
		if (e === '' && !isError) {
			setIsFocused(false)
		}
	}

	const changeHandler = (e) => {
		changeData(prevState => {
			return ({ ...prevState, [type]: e.value })
		})
	}

	let component = null

	switch(type) {
		case 'phone': 
			component = 
				<PhoneInput
					type={type}
					text={text}
					Icon={Icon}
					error={error}
					message={message}
					register={register}
					focusHandler={focusHandler}
					blurHandler={blurHandler}
					isFocused={isFocused}
				/>
		break
		case 'size':
			component = 
				<SizeInput
					type={type}
					text={text}
					Icon={Icon}
					message={message}
					focusHandler={focusHandler}
					blurHandler={blurHandler}
					changeHandler={changeHandler}
					isFocused={isFocused}
					submitErrors={submitErrors}
				/>
		break
		default:
			component = register ? 
				<TextInputHookFrom
					type={type}
					text={text}
					Icon={Icon}
					error={error}
					message={message}
					register={register}
					focusHandler={focusHandler}
					blurHandler={blurHandler}
					isFocused={isFocused}
				/>
				:
				<TextInput
					type={type}
					text={text}
					Icon={Icon}
					message={message}
					focusHandler={focusHandler}
					blurHandler={blurHandler}
					changeHandler={changeHandler}
					isFocused={isFocused}
					submitErrors={submitErrors}
					onlyNumbers={true}
				/>
		break
	}

	return (
		<div className={styles.root}>
			{component}
		</div>
	)
}

Input.propTypes = {
	type: PropTypes.string.isRequired,
	changeHandler: PropTypes.func,
	Icon: PropTypes.object.isRequired,
	text: PropTypes.string.isRequired,
	message: PropTypes.string,
	error: PropTypes.object,
	submitErrors: PropTypes.bool
}

export default React.memo(Input)