import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useForm } from "react-hook-form"

import Input from '../../inputResolver'
import FormResult from '../../../components/common/formResult'
import { ReactComponent as PhoneIcon } from '../../../icons/phone-icon.svg'
import { ReactComponent as UserIcon } from '../../../icons/user-icon.svg'

import { ModalContext } from '../../layout/ui'
import { fetchDo } from '../../../lib/utils'

import data from '../store'

import styles from './index.module.scss'
import Checkbox from '../../inputResolver/checkbox'

export const Form = ({ storredData, submitHandler }) => {
	const { register, handleSubmit, formState: { errors }  } = useForm()
	const { setModalComponent } = useContext(ModalContext)

	const onSubmitDefaultHandler = async (data) => {
		const result = await fetchDo(data, '/api/callback/')
		setModalComponent(<FormResult title={result.payload.title} text={result.payload.text} />)
	}

	const onSubmit = data => {
		typeof submitHandler === 'function'
			? submitHandler({...data, ...storredData})
			: onSubmitDefaultHandler(data);
		
		
	}

	return (
		<div className={styles.root}>
			<form onSubmit={handleSubmit((e) => onSubmit(e, storredData || {}))}>
				<div className={styles.title}>
					{data.title}
				</div>
				<div className={styles.text}>{data.text}</div>
				<div className={styles.inputsWrapper}>
					<div className={styles.inputWrapper}>
						<Input
							Icon={<PhoneIcon />}
							text='Телефон'
							type='phone'
							register={register}
							error={errors.phone}
							message={'+7'}
						/>
					</div>
					<div className={styles.inputWrapper}>
						<Input
							Icon={<UserIcon />}
							text='Ваше имя'
							type='name'
							register={register}
							error={errors.name}
							message={'Введите имя'}
						/>
					</div>
					<Checkbox 
						type='checkbox'
						error={errors.terms}
						message={'Это поле обязательно'}
						checked={false}
						register={register}
						label={<>Даю согласие на обработку <a href='/assets/SOPD_personal_data.pdf' target='_blank'>обработку персональных данных</a></>}
						name={'terms'}
					/>
					<Checkbox 
						type='checkbox'
						message={false}
						checked={false}
						register={register}
						label={<>Я согласен на <a href='/assets/SOPD_personal_data_ad.pdf' target='_blank'>получение рекламы</a></>}
						name={'commercial'}
					/>
				</div>
				<input type="submit" className={styles.action} value='Отправить' />
			</form>
		</div>
	)
}

Form.propTypes = {
	storredData: PropTypes.object
}