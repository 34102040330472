import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './index.module.scss'

const Button = ({ text, handler, isHeader = false }) => {
	return (
		<div className={clsx(styles.button, {
			[styles.isHeader] : isHeader
		})} onClick={handler}>
			<span>{text}</span>
		</div>
	)
}

Button.propTypes = {
	text: PropTypes.string.isRequired,
	handler: PropTypes.func,
	isHeader: PropTypes.bool
}

export default React.memo(Button)