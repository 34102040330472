import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { initializeYandexMetrica } from './modules/metrica'

import router from './controllers/router/'

import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<HelmetProvider>
		<Helmet>
		</Helmet>
		<RouterProvider router={router} />
	</HelmetProvider>
);

// initializeYandexMetrica();