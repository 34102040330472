import React from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'

import Column from './column'
import Copyright from './copyright'
import Link from './link'

import { ReactComponent as LogoIcon } from '../../../../icons/logo.svg'

import data from '../../store'

import styles from './index.module.scss'

const Footer = () => {
	return (
		<div className={styles.root}>
			<div className={styles.footerRow}>
				<NavLink name='empairo' to={'/'}  className={styles.logoWrapper}>
					<LogoIcon />
				</NavLink>
				<div className={styles.footerMenu}>
					{
						data.links.map((e, i) =>
							<Column
								link={e.url}
								title={e.text}
								items={e.additionalLinks}
								key={i}
							/>
						)
					}
				</div>
			</div>
			<div className={clsx([styles.footerRow, styles.footerRowCopyright])}>
				<div className={styles.copyright}>
					<Copyright />
				</div>
				<div className={styles.lawBlock}>
					{
						data.politics.map((e, i) =>
							<Link key={i} link={e.link} text={e.title} />
						)
					}
				</div>
			</div>
		</div>
	)
}

export default Footer