import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { ReactComponent as MainIcon } from '../../../../../icons/lev.svg'
import styles from './index.module.scss'

const MenuMainItem = ({ handler }) => {
	const { pathname } = useLocation()
	const match = '/' === pathname

	return (
		<NavLink to='/' onClick={() => handler()} className={clsx(styles.root, {
			[styles.isActive]: match
		})}>
			<div className={clsx(styles.link, {
				[styles.linkActive]: match
			})}>
				Главная
			</div>
			<div className={clsx(styles.iconWrapper, {
				[styles.iconActive]: match
			})}>
				<MainIcon />
			</div>
		</NavLink>
	)
}

MenuMainItem.propTypes = {
	handler: PropTypes.func.isRequired
}

export default React.memo(MenuMainItem)