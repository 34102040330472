import React from 'react'

import styles from './index.module.scss'

const Copyright = () => {
	const year = new Date()

	return (
		<div className={styles.copyright}>
			© ЭМПАЭРО, {year.getFullYear()}
		</div>
	)
}

export default React.memo(Copyright)